import { useStaticQuery, graphql } from "gatsby"

const usePrivacyPolicyPageData = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsPrivacyPolicy {
        nodes {
          title
          body {
            value
            links
            blocks
          }
          meta {
            updatedAt
          }
        }
      }
    }
  `)
  return data.allDatoCmsPrivacyPolicy.nodes[0]
}

export default usePrivacyPolicyPageData
